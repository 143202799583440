@tailwind base;
@tailwind components;
@tailwind utilities;


.background-container {
    width: 100%;
    min-height: 100vh;
    background: #0d0e12 url("../assets/bg.jpg") no-repeat center;
    background-size: cover;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    inset: 0;
}

/*.background-container:before,*/
/*.background-container:after {*/
/*    content: "";*/
/*    width: 40vmax;*/
/*    height: 40vmax;*/
/*    position: absolute;*/
/*    background: linear-gradient(45deg, #3023ae 0%, #ff0099 100%);*/
/*    filter: blur(8vmax);*/
/*    opacity: 0.1;*/
/*    left: 10vmin;*/
/*    top: -10vmin;*/
/*    animation: morph 15s linear infinite alternate, spin 20s linear infinite;*/
/*    z-index: 1;*/
/*    will-change: border-radius, transform;*/
/*    transform-origin: 55% 55%;*/
/*    pointer-events: none;*/
/*    backface-visibility: hidden;*/
/*}*/

/*.background-container:after {*/
/*    background: linear-gradient(45deg, #1fcfc3 0%, #1f91cf 100%);*/
/*    width: 70vmin;*/
/*    height: 70vmin;*/
/*    left: auto;*/
/*    right: -10vmin;*/
/*    top: auto;*/
/*    bottom: 0;*/
/*    animation: morph 10s linear infinite alternate,*/
/*    spin 26s linear infinite reverse;*/
/*    transform-origin: 40% 40%;*/
/*    filter: blur(8vmin);*/
/*    opacity: 0.05;*/
/*}*/

.overlay {
    position: absolute;
    inset: 0;
    background: #0d0e12 url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%22003232%22%20width%3D%2232%22%20height%3D%2232%22%20fill%3D%22none%22%20stroke%3D%22rgb%2830%2030%2040%20%2F%200.4%29%22%3E%3Cpath%20d%3D%22M0%20.5H31.5V32%22%2F%3E%3C%2Fsvg%3E") bottom;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), black);
}


@keyframes morph {
    0% {
        border-radius: 40% 60% 60% 40%/70% 30%;
    }
    100% {
        border-radius: 40% 60%;
    }
}

@keyframes spin {
    100% {
        transform: rotate(1turn);
    }
}
